import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as decode from 'jwt-decode';
import {AppState} from '../app.state';
import {UserProfile} from '../models/app.user.model';

@Injectable()
export class SecurityGuard implements CanActivate {
    loginMessageExpired: string = "Your session has expired. Please click the login button to log back in.";

    constructor(
        private appState: AppState,
        private router: Router,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let token = localStorage.getItem('token');

        if (token === null) {
            this.appState.setUserProfileSubject(null);
            this.router.navigate(['login']);
            return false;
        }

        let tokenPayload = decode(token);

        if (!tokenPayload) {
            this.appState.set("loginMessage", this.loginMessageExpired);
            this.appState.setUserProfileSubject(null);
            this.router.navigate(['login']);
            return false;
        }

        if (this.isJwtExpired(tokenPayload)) {
            this.appState.set("loginMessage", this.loginMessageExpired);
            this.appState.setUserProfileSubject(null);
            this.router.navigate(['login']);
            return false;

        } else {
            // await this.appState.fetchRolesAndPermission();
            let profile:UserProfile  = new UserProfile();
            profile.id = tokenPayload.UserId;
            profile.firstName = tokenPayload.FirstName;
            profile.lastName = tokenPayload.LastName;
            profile.parentBusinessId = tokenPayload.ParentBusinessId;
            profile.userName = tokenPayload.UserName;
            profile.position = tokenPayload.Position;

            if (Array.isArray(tokenPayload.Roles)) {
              profile.roles = tokenPayload.Roles;
            } else {
              profile.roles.push(tokenPayload.Roles);
            }
            this.appState.setUserProfileSubject(profile);
            return true;
        }


    }

    private validateNavigation(state: RouterStateSnapshot) {

    }

    isJwtExpired(jwt: any) {
        var current_time = Date.now() / 1000;
        return jwt.exp < current_time
    }
}
