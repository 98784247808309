export interface User {
    username: string;
    password: string;
}


export class UserProfile {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
    parentBusinessId: string;
    position:string;
    roles:Array<string> = new Array<string>();
    photo:any;
}

export class Register {
    firstName: string;
    lastName: string;
    username: string;
    businessName: string;
    country: string;
    timezone: string;
    isTermOfService: boolean;
}

export class ForgotPasswordModel {
    emailid: string;
    username: string;
    password: string;
}

export class LoginModel {
    id: string;
    username: string;
    firstName: string;
    lastName:  string;
    token: string;
}

export class SubscriptionData {
    subscriptionId: string;
    subscriptionStatus: string;
    subscriptionTrialEndsAt: string;
    trialRemainingDays: number;
}

export class LoginSubmitModel {
    userid: string;
    password: string;
}

export class FinaliseModel {
    userid: string;
    password: string;
    token: string;
}

export interface SubscriptionDetails {
    subscriptionId: string;
    subscriptionStatus: string;
    subscriptionTrialEndsAt: Date,
    trialRemainingDays: number
}

