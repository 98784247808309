import {Component, OnInit} from '@angular/core';
import {onMainContentChange} from './animations/animations';

import {AppState} from './app.state';
import {TextValue} from './shared/interface';
import {isDate} from 'util';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';


// if (environment.production) {
//   LogRocket.init('lkgehd/test', {
//     console: {
//       isEnabled: {
//         log: true,
//         info: false,
//         debug: false,
//         warn: true,
//         error: true
//       },
//     },
//   });
// }

export const DummyGUID = '00000000-0000-0000-0000-000000000000';
export const Success = 'Success';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [onMainContentChange]
})
export class AppComponent implements OnInit {

    constructor(private appState: AppState) {
    }

    ngOnInit() {
        const checkUserProfile = new Subject();
        this.appState.userProfileSubject.pipe(
            takeUntil(checkUserProfile)
        ).subscribe(user => {
            if (user) {
                checkUserProfile.next(true);
                checkUserProfile.complete();
                // LogRocket.identify(user.id, {
                //     name: `${user.firstName} ${user.lastName}`,
                //     email: user.userName
                // });
            }
        })
    }
}

export const EMAIL_REGEX =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const CONTECT_NO_REGEX =/^(\\+[0-9]{1,3})?[-.\\s]?[0-9]{10}$/;

document.addEventListener('wheel', function(event) {
    if (document.activeElement['type'] === 'number') {
        (document.activeElement as HTMLElement).blur();
    }
});

export const formatREString = (cRe: string, re: string): string => {
    return cRe && cRe != '' ? cRe.concat(','.concat(re)) : re;
};

export const hasPermission = (appState: AppState, moduleName: string | string[], permission: string | string[]): boolean => {
    return appState.haveAccessToModule(moduleName, permission);
};

/**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
export const uuid = (parts: number = 1): string => {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
        // tslint:disable-next-line:no-bitwise
        const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        stringArr.push(S4);
    }
    return stringArr.join('-');
};

/**
 * Helper to produce an array of enum descriptors.
 * @param enumeration Enumeration object.
 * @param mapOnlyValue Return Only Values
 * @param separatorRegex Regex that would catch the separator in your enum key.
 * @param replaceValue Replace value matched by Regex
 */
export const enumToArray = <T>(enumeration: T, mapOnlyValue: boolean = false, separatorRegex: RegExp = /_/g, replaceValue: string = ' '): TextValue[] | any[] => {
    return (Object.keys(enumeration) as Array<keyof T>)
        .filter(key => isNaN(Number(key)))
        .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
        .map(key => (mapOnlyValue ? enumeration[key] : {
            value: enumeration[key],
            text: String(key).replace(separatorRegex, replaceValue),
        }));
}

export const getDateWithZ = (date: any) => {
    return isDate(date) ? date : (date && date.toLowerCase().endsWith('z') ? date : !hasOffset(date) ? date.concat('z') : date);
};

export const getOffsetStr = (input: any) => {
    const groups = input.match(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\.\d+)?([+-]\d{2}:?\d{2})$/);
    return groups ? groups[4] : null;
};

export const hasOffset = (input: any) => {
    return (!!getOffsetStr(input));
};

export const printPdf = (src: Blob) => {
    const fileLink = window.URL.createObjectURL(src);
    const iframe = (document.getElementById('print_pdf') as HTMLIFrameElement) || document.createElement('iframe');
    iframe.src = fileLink;
    iframe.id = 'print_pdf';
    iframe.name = 'print_pdf';
    iframe.style.display = 'none';
    iframe.onload = () => {
        iframe.focus();
        iframe.contentWindow.print();
    };
    document.body.appendChild(iframe);
};

export const downloadPdf = (src: any) => {
    let newBlob = new Blob([src], {type: 'application/pdf'});
    const data = window.URL.createObjectURL(newBlob);

    let link = document.createElement('a');
    link.href = data;
    link.target = '_blank';
    // link.download = (this.id && this.title.toLowerCase() == 'benefit form') ? `Benefit-${this.id}.pdf` : ((this.id && this.title.toLowerCase() == 'service voucher') ? `ServiceVoucher-${this.id}.pdf` : ((this.id && this.title.toLowerCase() == 'bulk bill') ? `Bulkbill-${this.id}.pdf` : ((this.id && this.title.toLowerCase() == 'dva allied health') ? `DVA_Allied_Health-${this.id}.pdf` : ((this.id && this.title.toLowerCase() == 'dva paperless') ? `DVA_Paperless-${this.id}.pdf` : `GenerateStatement-${this.id}.pdf`))));
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );

    setTimeout(function() {
        // window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}

export const blobToBase64 = (src: Blob, callback: (base64Str: string | null) => void) => {
    if (!src) {
        return
    }
    const reader = new FileReader();
    reader.readAsDataURL(src);
    reader.onloadend = function () {
        callback(reader.result as string);
    };
};

declare global {
    interface Array<T> {
        insert(index: number, ...args: any): Array<T>;

        containsAny(...args: any): boolean;
    }

    interface String {
        toSlug(): string;
    }
}

Array.prototype.insert = function(index: number) {
    index = Math.min(index, this.length);
    arguments.length > 1
    && this.splice.apply(this, [index, 0].concat([].pop.call(arguments)))
    && this.insert.apply(this, arguments);
    return this;
};

Array.prototype.containsAny = function() {
    return Array.from(arguments).some(i => this.includes(i));
};

String.prototype.toSlug = function() {
    let str = this;

    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '_') // collapse whitespace and replace by -
        .replace(/-+/g, '_'); // collapse dashes
    return str;
};
