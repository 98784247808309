import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from './services/app.authenication.service';


@Injectable()
export class AppErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        return throwError(this.onCatchError(err));
      }));
  }

  onCatchError(err): string {
    console.log(err)
    if (err.status === 401) {
      this.authService.logout();
      this.router.navigate(['login']);
      return "Unauthorized";
    }
    if (err.status == 0) {
      return ((err['message'] ? err['message'] : err['statusText']) || err['statusText']);
    }
    const defaultErrorStr: string = 'Unfortunately, there\'s an issue on our end right now. Please, try again later.';
    return err ? (err.error ? err.error['message'] : defaultErrorStr) : defaultErrorStr;
  }
}
