<!--<mat-sidenav-container hasBackdrop="false" fullscreen [ngClass]="{ 'kavanii-drawer' : appState.authenicatied, 'kavanii-drawer-non': !appState.authenicatied }">-->

<!--  <mat-sidenav #drawer [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" [opened]="true" class="nav-container" *ngIf="appState.authenicatied">-->
<!--    <app-sidenav></app-sidenav>-->
<!--  </mat-sidenav>-->

<!--  <mat-sidenav-content [@onMainContentChange]="appState.sideNavToggle ? (mobileQuery.matches ? 'close' : 'open'): 'close'" [ngClass]="{ 'main-content': appState.authenicatied,-->
<!--                                   'stand-content' : !appState.authenicatied,-->
<!--                                   'contrast-content': appState.contrastBackground }">-->
<!--    <div class="row pl-0 pr-0" *ngIf="appState.authenicatied">-->
<!--      <div class="col-xl-12 text-right topbar-header">-->
<!--        <div class="profilePanel mr-5">-->
<!--          <img class="person mr-3" src="../../assets/user.jpg" alt="" [matMenuTriggerFor]="menu" matTooltip="">-->
<!--          <span>Hi,{{ appState.userProfile?.firstName }}</span>-->
<!--        </div>-->
<!--        <mat-menu #menu="matMenu" class="kavanii-menu">-->
<!--          <button mat-menu-item><mat-icon>people</mat-icon>Account</button>-->
<!--          <button mat-menu-item><mat-icon>help</mat-icon> Help</button>-->
<!--          <mat-divider></mat-divider>-->
<!--          <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon>Logout</button>-->
<!--        </mat-menu>-->
<!--      </div>-->
<!--    </div>-->

<!--[ngClass]="{ 'container': !appState.authenicatied, 'contrast-content': appState.contrastBackground }"-->
<!--    <div class="container">-->
<div kendoDialogContainer></div>
      <router-outlet></router-outlet>
<!--    </div>-->
<!--  </ng smat-sidenav-content>-->
<!--</mat-sidenav-container>-->


<!-- <app-footer></app-footer> -->
