import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as decode from 'jwt-decode';
import {AppState} from '../app.state';
import {UserProfile} from '../models/app.user.model';
import {AuthService} from '../services/app.authenication.service';

@Injectable()
export class SecurityGuard implements CanActivate {
    loginMessageExpired: string = 'Your session has expired. Please click the login button to log back in.';

    constructor(
        private appState: AppState,
        private authService: AuthService,
        private router: Router,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        if (!this.authService.isAuthenticated()) {
            this.appState.set('loginMessage', this.loginMessageExpired);
            this.appState.setUserProfileSubject(null);
            this.router.navigate(['login']);
            return false;

        } else if (this.authService.getToken()) {
            let tokenPayload = decode(this.authService.getToken());
            // await this.appState.fetchRolesAndPermission();
            let profile: UserProfile = new UserProfile();
            profile.id = tokenPayload.UserId;
            profile.firstName = tokenPayload.FirstName;
            profile.lastName = tokenPayload.LastName;
            profile.parentBusinessId = tokenPayload.ParentBusinessId;
            profile.userName = tokenPayload.UserName;
            profile.position = tokenPayload.Position;

            if (Array.isArray(tokenPayload.Roles)) {
                profile.roles = tokenPayload.Roles;
            } else {
                profile.roles.push(tokenPayload.Roles);
            }
            this.appState.setUserProfileSubject(profile);
            return true;
        }
        return false;
    }
}
