import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule,} from '@angular/platform-browser/animations';
import {AuthorisationComponent} from './authorisation/authorisation.component';
import {AppState} from './app.state';
import {SecurityGuard} from './authorisation/authorisation.guard';
import {AuthInterceptor} from './app.http-interceptor';
import {AuthService} from './services/app.authenication.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BusinessService} from './services/app.business.service';
import {CommonModule} from '@angular/common';
import {RoleService} from './services/app.role.service';
import {BaseService} from './services/app.base.service';
import {AppErrorInterceptor} from './app.error-interceptor';
import {OverlayModule} from '@angular/cdk/overlay';
import {PopupModule} from '@progress/kendo-angular-popup';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {
  AppointmentHandlerService
} from './layout/content-full-layout/appointment/schedular-main/schedular/service/appointment-handler.service';
import {AppInit} from './app-init';
import { IconsModule } from '@progress/kendo-angular-icons';
import {BlockUIModule} from 'ng-block-ui';


@NgModule({
  declarations: [
    AppComponent,
    AuthorisationComponent,
  ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        // NoopAnimationsModule,
        HttpClientModule,
        OverlayModule,
        PopupModule,
        DialogModule,
        IconsModule,
        BlockUIModule
    ],
  exports: [
    BrowserAnimationsModule
  ],
  providers: [
    BaseService,
    AuthInterceptor,
    AppState,
    SecurityGuard,
    AuthService,
    BusinessService,
    RoleService,
    AppointmentHandlerService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInit,
      deps: [AppState],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppErrorInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
