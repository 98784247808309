import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PractitionerSpecialityModel} from '../../../../../../models/app.staff.model';
import {Schedule} from '../../../../../../models/app.appointment.model';
import {CalenderConfigurationModel} from 'src/app/models/app.calender-configuration.model';

export const REBOOK = 0;
export const MOVE = 1;
export const NONE = -1;

@Injectable()
export class AppointmentHandlerService {

  private _editApptSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _openApptDialogSubject: BehaviorSubject<Schedule> = new BehaviorSubject<Schedule>(null);
  private _getSchedularConfigration: BehaviorSubject<CalenderConfigurationModel> = new BehaviorSubject<CalenderConfigurationModel>(null);
  private _hideApptTooltipPopup: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _openApptTooltipPopup: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _rebookMoveAppt: BehaviorSubject<number> = new BehaviorSubject<number>(NONE);
  private _loadAppointment: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _schedulerState: SchedulerState = {} as SchedulerState;
  private _tooltipPopupData: any;
  private _isRebookMoveAppointment: boolean = false;

  constructor() {
  }

  get editApptSubject(): BehaviorSubject<boolean> {
    return this._editApptSubject;
  }

  get openApptDialogSubject(): BehaviorSubject<Schedule> {
    return this._openApptDialogSubject;
  }

  get getSchedularSettingConfigration(): BehaviorSubject<CalenderConfigurationModel> {
    return this._getSchedularConfigration;
  }

  get hideApptTooltipPopup(): BehaviorSubject<boolean> {
    return this._hideApptTooltipPopup;
  }

  get openApptTooltipPopup(): BehaviorSubject<boolean> {
    return this._openApptTooltipPopup;
  }

  get tooltipPopupData(): any {
    return this._tooltipPopupData;
  }

  get rebookMoveAppt(): BehaviorSubject<number> {
    return this._rebookMoveAppt;
  }

  get loadAppointment(): BehaviorSubject<string> {
    return this._loadAppointment;
  }

  get isRebookMoveAppointment(): boolean {
    return this._isRebookMoveAppointment;
  }

  set isRebookMoveAppointment(value: boolean) {
    this._isRebookMoveAppointment = value;
  }

  get schedulerState(): SchedulerState {
    return this._schedulerState;
  }

  notifyEditAppt(value = true) {
    this.editApptSubject.next(value);
  }

  notifyRebookMove(value: number) {
    this.isRebookMoveAppointment = value == REBOOK || value == MOVE;
    this.rebookMoveAppt.next(value);
  }

  reloadAppt(id: string) {
    this.loadAppointment.next(id);
  }

  hideTooltip() {
    this.hideApptTooltipPopup.next(true);
  }

  openTooltip() {
    this.openApptTooltipPopup.next(true);
  }

  setTooltipData(data: any) {
    this._tooltipPopupData = data;
  }

  setSchedulerState(
    currentDate: Date,
    currentView: string,
    practitioners: PractitionerSpecialityModel[],
    showBubblePopup?: boolean) {
    this.schedulerState.currentDate = currentDate;
    this.schedulerState.currentView = currentView;
    this.schedulerState.showBubblePopup = showBubblePopup;
    this.schedulerState.practitioners = practitioners;
  }
}

export interface SchedulerState {
  currentDate: Date,
  currentView: string,
  practitioners: PractitionerSpecialityModel[],
  showBubblePopup: boolean,
}
