import {Injectable} from '@angular/core';
import {BusinessFormModel, BusinessModel} from '../models/app.business.model';
import {LocationGridModel, LocationSelectionModel} from '../models/app.location.model';
import {BaseService} from './app.base.service';
import {CalenderConfigurationModel} from '../models/app.calender-configuration.model';
import {shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BusinessService extends BaseService {
    getAllBusiness() {
        return this.http.get<BusinessModel[]>(this.environmentSettings.apiBaseUrl + "/GetAllBusiness");
    }

    getLocation(locationId: string) {
        return this.http.get<any>(this.environmentSettings.apiBaseUrl + `/GetBusinessLocationById/${locationId}`);
    }

    addLocation(locationDetails: any) {
        return this.http.post<any>(this.environmentSettings.apiBaseUrl + "/CreateLocation", locationDetails);
    }

    updateLocation(locationDetails: any) {
        return this.http.post<any>(this.environmentSettings.apiBaseUrl + "/UpdateLocation", locationDetails);
    }

    getParentBusiness(id: string) {
        return this.http.get<BusinessFormModel>(this.environmentSettings.apiBaseUrl + `/GetParentBusinessById/${id}`);
    }

    updateBusiness(businessModel: BusinessModel) {
        return this.http.post<any>(this.environmentSettings.apiBaseUrl + "/UpdateBusiness", businessModel, { responseType: 'text' as 'json' });
    }

    getLocationsByBusiness(parentBusinessId: string,isActiveRequried:boolean=true) {
        let params = {} 
        if (isActiveRequried) {
            params['isActive'] = true;
        }
        return this.http.get<LocationGridModel[]>(this.environmentSettings.apiBaseUrl + `/GetAllLocationsByParentBusinessId/${parentBusinessId}`, {params} ).pipe(
            shareReplay(1)
        );
    }

    getLocationsForCurrentUser(isActive?: boolean) {
        const params = {};
        if (typeof isActive === 'boolean') {
            params['isActive'] = isActive;
        }
        return this.http.get<LocationSelectionModel[]>(this.environmentSettings.apiBaseUrl + "/GetLocationsByLoginUser", {
            params
        });
    }

    getTimeandIntervalByLocation(locationId: string) {
        return this.http.get<CalenderConfigurationModel>(this.environmentSettings.apiBaseUrl + "/GetTimeandIntervalByLocation", {params: {'locationId': locationId}});
    }
}
