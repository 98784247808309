import {Injectable} from '@angular/core';
import {BaseService} from './app.base.service';
import {LocationRoleAndPermissionModel, RoleModel, RoleModule} from '../models/app.role.model';

@Injectable()
export class RoleService extends BaseService {
  getAllRoles() {
    return this.http.get<RoleModel[]>(this.environmentSettings.apiBaseUrl + '/GetAllRoles');
  }

  getRoleById(id: string) {
    return this.http.get<any>(this.environmentSettings.apiBaseUrl + `/GetRolesById/${id}`);
  }

  // getRoleAndPermissions(id: string) {
  //     return this.http.get<PermissionRoleModel[]>(this.environmentSettings.apiBaseUrl + `/GetAppRoleAndPermissionModulesByBusinessId/${id}`);
  // }

  getRolesByCurrentUser() {
    return this.http.get<{ [x: string]: { [x: string]: string } }>(this.environmentSettings.apiBaseUrl + `/GetRolesByCurrentUser`).pipe();
  }

  getRoleAndPermissionsByUserLocation(id: string) {
    return this.http.get<LocationRoleAndPermissionModel[]>(this.environmentSettings.apiBaseUrl + `/GetAppRoleAndPermissionModulesByUserLocationId/${id}`);
  }

  getAllBusinessAccessByModule() {
    return this.http.get<RoleModule[]>(this.environmentSettings.apiBaseUrl + '/GetAllBusinessAccessbyModule');
  }

  updateBusinessRoleModulesAccess(data: { id: string, moduleRoleAccess: number, modulePartialAccess: string }[]) {
    return this.http.put(this.environmentSettings.apiBaseUrl + '/UpdateBusinessRoleModulesAccess', data);
  }

  addCustomRole(roleName: string) {
    return this.http.post(this.environmentSettings.apiBaseUrl + '/CreateBusinessRoles', {}, {
      params: {
        roleName: roleName
      }
    });
  }

  editCustomRole(id: string, roleName: string) {
    return this.http.post(this.environmentSettings.apiBaseUrl + '/UpdateBusinessRoles', {}, {
      params: {
        businessRoleId: id,
        roleName: roleName
      }
    });
  }

  deleteCustomRole(id: string) {
    return this.http.delete(this.environmentSettings.apiBaseUrl + '/DeleteBusinessRoles', {
      params: {
        businessRoleId: id
      }
    });
  }

  resetBusinessRoleModuleAccess() {
    return this.http.get<any>(this.environmentSettings.apiBaseUrl + '/ResetBusinessRoleModuleAccess');
  }
}
