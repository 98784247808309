import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.indexOf('googleapis') > -1) {
            return next.handle(req);
        }
        let idToken = localStorage.getItem('token');

        if ((req.url.indexOf('GetConcentDetailsByInvoiceId') > -1 || req.url.indexOf('CreateConcentDetails') > -1) && req.headers.get("medicare-token")) {
            idToken = req.headers.get("medicare-token");
        }

        if (idToken) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization',
                    'Bearer ' + idToken)
            });

            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}
