import {AppState} from './app.state';
import * as decode from 'jwt-decode';

export function AppInit(appState: AppState): () => Promise<void> {
    return () => new Promise<void>(async (resolve, _) => {
        try {
            if (localStorage.getItem('token')) {
                const jwt = decode(localStorage.getItem('token'));
                const expirationDate = new Date(0);
                expirationDate.setUTCSeconds(jwt.exp)
                if (expirationDate > new Date()) {
                    await appState.fetchRolesAndPermission();
                }
            }
        } catch (e) {

        } finally {
            resolve();
        }
    });
}
